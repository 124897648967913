
//*****************************************************************************

import * as getVar from "../common/page.vars.js";
import * as getFunc from "../common/page.functions.js";

//*****************************************************************************

export const ctrPageMessage = ( function () {

 const setMaxMessages = 1;

 const setPageMessage = function(obj) {

  let selectMainContainer = null;
  let selectMessage = null;

  const ref = this;

  ref.openPageMessage = function() {

   ref.setMainContainer();

   const selectMessages = selectMainContainer.getElementsByClassName("message");

   if(selectMessages.length >= setMaxMessages) { ref.closeMaxMessages(); } else {

    ref.setMessageContainer();

   }

  }

  ref.setMainContainer = function() {

   const selectPageMessage = document.getElementsByClassName("page-message").length;

   if(selectPageMessage === 0) {

    const createMainContainer = document.createElement("div");
    createMainContainer.classList.add("page-message");

    if(getFunc.getScrollBarWidth() === 0) {createMainContainer.classList.add("sp"); }

    document.querySelector("main").appendChild(createMainContainer);

    selectMainContainer = createMainContainer;

   } else {

    selectMainContainer = document.querySelector(".page-message");

   }

  }

  ref.setMessageContainer = function() {

   const createMessage = document.createElement("div");
   createMessage.classList.add("message");
   createMessage.classList.add(obj.setClassMessage);
   createMessage.innerText = obj.setPageMessage;

   selectMessage = createMessage;

   const createMessageClose = document.createElement("a");
   createMessageClose.classList.add("close");
   createMessageClose.setAttribute("href","#");

   selectMainContainer.appendChild(selectMessage);

   selectMessage.style.opacity = 0;

   const opacityTimeout = setTimeout( function() {

    selectMessage.style.opacity = 1;
    ref.setTransitionEnd(selectMessage);

    clearTimeout(opacityTimeout);

   }, 0);

   if(obj.setCloseButton) {

    selectMessage.appendChild(createMessageClose);

    createMessageClose.addEventListener("click", function(evt) {

     evt.preventDefault();
     ref.closePageMessage(selectMessage);

    });

   }

   if(obj.setCollapse) {

    const collapseTimeout = setTimeout( function() {

     if(selectMainContainer.contains(selectMessage)) {

      ref.closePageMessage(selectMessage);

     }

     clearTimeout(collapseTimeout);

    }, obj.setCloseTime);

   }

  }

  ref.closeMaxMessages = function() {

   const selectMessages = selectMainContainer.getElementsByClassName("message");

   if(selectMessages.length >= setMaxMessages) {

    selectMainContainer.firstChild.remove();

    if(selectMessages.length >= setMaxMessages) {

     ref.closeMaxMessages();

    } else {

     ref.setMessageContainer();

    }

   }

  }

  ref.closePageMessage = function(elem) {

   elem.style.opacity = 0;

   elem.addEventListener("transitionend", function transition() {

    elem.remove();

    const selectMessages = selectMainContainer.getElementsByClassName("message");

    if(selectMessages.length === 0) {

     selectMainContainer.remove();
     getFunc.setGoToTopButtons();

    }

   });

  }

  ref.setTransitionEnd = function(obj) {

   obj.addEventListener("transitionend", function transition() {

    obj.removeAttribute("style");

    obj.removeEventListener("transitionend",transition);

   });

  }

 }

 //****************************************************************************

 const setAlertMessage = function(msg) {

  const obj = {};

  obj.setCloseTime = 3000;
  obj.setCollapse = true;
  obj.setCloseButton = true;
  obj.setClassMessage = "alert";
  obj.setPageMessage = msg;

  const ref = new setPageMessage(obj);
  ref.openPageMessage();

 }

 //****************************************************************************

 const setConfirmMessage = function(msg) {

  const obj = {};

  obj.setCloseTime = 3000;
  obj.setCollapse = true;
  obj.setCloseButton = true;
  obj.setClassMessage = "confirm";
  obj.setPageMessage = msg;

  const ref = new setPageMessage(obj);
  ref.openPageMessage();

 }

 //****************************************************************************

 return {

  setAlertMessage : function (msg) { setAlertMessage(msg); },
  setConfirmMessage : function (msg) { setConfirmMessage(msg); }

 }

});

//*****************************************************************************

// const setPageMessage = new ctrPageMessage;
//
// setPageMessage.setAlertMessage("Hello Alert ...");
// setPageMessage.setConfirmMessage("Hello Confirm ...");

//*****************************************************************************
