
//*****************************************************************************

import * as getVar from "../common/page.vars.js";
import * as getFunc from "../common/page.functions.js";

//*****************************************************************************

export const ctrAccordion = ( function () {

 "use strict";

 const arrItemsAccordion = [];

 const classItemsAccordion = document.querySelectorAll(".departments .plugin-accordion .item");

 const openAccordionItem = function(setItem) {

  const item = arrItemsAccordion[(setItem - 1)];
  const classItemContent = item.querySelector(".item-content");

  if(!classItemContent.classList.contains("open")) {

   openItemContent(item);

  }

 }

 const setActiveItem = function(item) {

  const classItemsLabel = item.querySelector(".item-header");
  const classItemContent = item.querySelector(".item-content");

  classItemsLabel.classList.add("open");
  classItemContent.classList.add("open");

 }

 const closeItemsContent = function() {

  arrItemsAccordion.forEach ( function (item) {

   const classItemContent = item.querySelector(".item-content");

   if(classItemContent.classList.contains("open")) {

    closeItemContent(item);

   }

  });

 }

 const closeItemContent = function(item) {

  const classItemsLabel = item.querySelector(".item-header");
  const classItemContent = item.querySelector(".item-content");

  setContentHeight(classItemContent);

  classItemsLabel.classList.remove("open");
  classItemContent.classList.remove("open");

  setTimeout(() => {

   classItemContent.style.height = null;

  }, 0);

 }

 const openItemContent = function(item) {

  const classItemsLabel = item.querySelector(".item-header");
  const classItemContent = item.querySelector(".item-content");

  if(classItemContent.classList.contains("open")) {

   closeItemContent(item);

  } else {

   closeItemsContent();

   setTimeout(() => {

    setContentHeight(classItemContent);

    classItemsLabel.classList.add("open");
    classItemContent.classList.add("open");

   }, 0);

   setTransitionEnd(classItemContent);

  }

 }

 const setTransitionEnd = function(item) {

  item.addEventListener("transitionend", function setContentHeight() {

   item.style.height = null;
   item.removeAttribute("style");

   item.removeEventListener("transitionend",setContentHeight);

  });

 }

 function setContentHeight(content) {

  content.style.height = content.scrollHeight + "px";

 }

 const init = function(setItem) {

  classItemsAccordion.forEach( item => {

   const classItemsLabel = item.querySelector(".item-header");

   arrItemsAccordion.push(item);

   if(arrItemsAccordion.length === setItem) {

    window.addEventListener("load", setActiveItem(item));

   }

   classItemsLabel.addEventListener("click", function() {

    openItemContent(item);

   });

  });

 };

 return {

  init : function (setItem) { init(setItem); },

  openAccordionItem : function (setItem) { openAccordionItem(setItem); }

 };

});

//*****************************************************************************

// const accordion = new ctrAccordion;
//
// accordion.init(setItem);

//*****************************************************************************
