
//*****************************************************************************

import * as getVar from "../common/page.vars.js";

//*****************************************************************************

import { ctrPageMessage } from "../common/page.message.js";

const setMessage = new ctrPageMessage;

//*****************************************************************************

export const scrollToTop = function () {

 const position = document.body.scrollTop || document.documentElement.scrollTop;

 if(position) { window.scrollTo({top: 0, behavior: "smooth"}); }

};

//*****************************************************************************

export const getReplaceFormString = function (string) {

 return string.replace(/(<([^>]+)>)/gi,"").trim();

}

//*****************************************************************************

export const getCookieName = function (name) {

 const cookieArr = document.cookie.split(";");

 for(let i = 0; i < cookieArr.length; i++) {

  let cookiePair = cookieArr[i].split("=");

   if(name == cookiePair[0].trim()) {

    return decodeURIComponent(cookiePair[1]);
   }

  }

 return null;

}

//*****************************************************************************

export const getTimestampNow = function () {

 const msec = Date.now();

 return Math.round(msec / 1000);

}

//*****************************************************************************

export const preloadImages = function (srcImageList) {

 let img;
 let imgArr = [];
 let imgArrLength = srcImageList.length;

 for (let i = 0; i < srcImageList.length; i++) {

  img = new Image();

  img.onload = function() {

   imgArrLength = imgArrLength -= 1;

   if (imgArrLength <= 0) {

    // console.log("Images Preload End ...");

   }

  };

  img.src = srcImageList[i];
  imgArr.push(img);

 }

}

//*****************************************************************************

export const getRandomInt = function (max) {

 return Math.floor(Math.random() * max);

}

//*****************************************************************************

export const setLoader = function () {

 const div = document.createElement("div");

 div.classList.add("loader");

 document.body.appendChild(div);

}

export const removeLoader = function () {

 const loader = document.querySelector(".loader")

 setTimeout(() => {

  loader.classList.add("loader-hidden");

 }, 300);

 loader.addEventListener("transitionend", function setLoader() {

  document.body.removeChild(loader);

  loader.removeEventListener("transitionend",setLoader);

 });

}

//*****************************************************************************

export const getCurrentPage = function () {

 let url = window.location.href;
 let splitUrl = url.toString().split("/");
 let setPage = splitUrl[3].split("?");

 return setPage[0].toLowerCase();

}

//*****************************************************************************

export const getLocationParam = function (pid) {

 let getUrlData = window.location.search;

 if(!getUrlData) return false;

 let getUrlParams = getUrlData.split("?")[1];
 let setUrlParams = getUrlParams.split("&");

 for(let i = 0; i < setUrlParams.length; i++) {

  let setPairValue = setUrlParams[i].split("=");

  if(setPairValue[0].toLowerCase() === pid.toLowerCase()) return setPairValue[1];

 }

 return false;

}

//*****************************************************************************

export const setGoToTopButtons = function() {

 if(document.getElementsByClassName("btns-go-top").length) {

  const selectGoToTop = document.querySelector(".btns-go-top");
  const getDisplay = window.getComputedStyle(selectGoToTop).display;

  if(getDisplay === "none") {

   selectGoToTop.style.setProperty("display","block");

  }

 }

}

export const removeGoToTopButtons = function() {

 if(document.getElementsByClassName("btns-go-top").length) {

  const selectGoToTop = document.querySelector(".btns-go-top");
  const getDisplay = window.getComputedStyle(selectGoToTop).display;

  if(getDisplay === "block") {

   selectGoToTop.style.setProperty("display","none");

  }

 }

}

//*****************************************************************************

const setPageLoader = function() {

 const loader = document.createElement("div");
 loader.classList.add("loader","spin");
 document.body.appendChild(loader);

}

const removePageLoader = function() {

 if(document.getElementsByClassName("loader").length) {

  const selectLoader = document.querySelector(".loader");

  selectLoader.classList.add("hide");

  selectLoader.addEventListener("transitionend", function removeLoader() {

   selectLoader.remove();

   selectLoader.removeEventListener("transitionend",removeLoader);

  });

 }

}

//*****************************************************************************

export const setMessageLoader = function() {

 const ref = this;

 let stopTimeout = null;

 ref.create = function() {

  const selectLoader = document.querySelector(".loader");

  if(selectLoader === null) {

   setPageOverflow();

   const div = document.createElement("div");
   div.classList.add("loader","spin");
   document.body.prepend(div);

   ref.faild();

  }

 }

 ref.remove = function(callback,msg) {

  const selectLoader = document.querySelector(".loader");

  if(selectLoader !== null) {

   setTimeout( function() {

    selectLoader.classList.add("hide");

    selectLoader.addEventListener("transitionend", function removeLoader() {

     removePageOverflow();
     selectLoader.remove();
     clearTimeout(stopTimeout);

     callback(msg);

     selectLoader.removeEventListener("transitionend",removeLoader);

    });

   }, 150);

  }

 }

 ref.faild = function() {

  stopTimeout = setTimeout( function() {

   ref.remove(setMessage.setAlertMessage,getVar.msg.course_form_33);

  }, 60000);

 }

 ref.create();

}

//*****************************************************************************

export const setPageOverflow = function() {

 if(arguments.length > 0 && arguments[0] === "overlay") {

  document.body.classList.add("body-overlay");

 }

 if(arguments.length > 0 && arguments[0] === "loader") {

  setPageLoader();

 }

 if(arguments.length > 0 && arguments[0] === "overlay-loader") {

  document.body.classList.add("body-overlay");

  setPageLoader();

 }

 const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;

 document.body.style.setProperty("overflow","hidden","important");
 document.body.style.setProperty("padding-right",scrollBarWidth + "px");

}

export const removePageOverflow = function() {

 if(arguments.length > 0 && arguments[0] === "overlay") {

  document.body.classList.remove("body-overlay");

 }

 if(arguments.length > 0 && arguments[0] === "loader") {

  removePageLoader();

 }

 if(arguments.length > 0 && arguments[0] === "overlay-loader") {

  document.body.classList.remove("body-overlay");

  removePageLoader();

 }

 const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;

 document.body.style.removeProperty("padding-right",scrollBarWidth + "px");
 document.body.style.removeProperty("overflow","hidden","important");
 document.body.removeAttribute("style");

}

//*****************************************************************************

export const getScrollBarWidth = function() {

 return window.innerWidth - document.documentElement.clientWidth;

}

//*****************************************************************************

export const setSyslogMessage = function(msg) {

 const data = { "Message" : msg }

 fetch("./inc/class.basic.syslog.php", {

  method: "POST",

  headers: {

   "Accept": "application/json",
   "Content-Type": "application/json"

  },

  body: JSON.stringify(data)

 });

}

//*****************************************************************************
