
//*****************************************************************************

export const form = {

 regExName : /^[a-zäöüß\-\s]+$/i,
 regExMail : /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,3})$/,
 regExPhone : /^[0-9\/\s]+$/,
 regExMessage : /^[a-zäöüß\d\-_.,:/+@|%*{}()!?\s]+$/i,
 regExPassword : /^[a-z0-9\-]+$/i,
 regExFormRow : /^[a-zäöüß\-_.&\[\],:\s]+$/i,

}

export const hostname = document.location.hostname;

export const setFormMaxMessage = 600;

export const uploadFileSizeMb = 8;
export const uploadFileSizeBytes = (uploadFileSizeMb * 1024000);
export const uploadFileExtensions = ["application/pdf"];

export const sessionInterval = 10000;
export const sessionTimeout = 3600;

let scrollbar = null;
function setScrollBar(val) { scrollbar = val; }
export { scrollbar,setScrollBar };

export const msg = {

 course_form_01 : "Das Infofeld muss mindestens aus #1 Buchstaben bestehen",

 course_form_02 : "Die Sport ID darf nicht leer sein",
 course_form_03 : "Die Sport ID muss mindestens aus #1 Buchstaben bestehen",

 course_form_04 : "Die Leitung darf nicht leer sein",
 course_form_05 : "Die Leitung muss mindestens aus #1 Buchstaben bestehen",

 course_form_06 : "Bitte die Kontaktdaten auswählen",

 course_form_07 : "Der Zeitraum darf nicht leer sein",
 course_form_08 : "Die Zeitraum muss mindestens aus #1 Buchstaben bestehen",

 course_form_09 : "Der Termin darf nicht leer sein",
 course_form_10 : "Die Termin muss mindestens aus #1 Buchstaben bestehen",

 course_form_11 : "Bitte den Ort auswählen",

 course_form_12 : "Die Route darf nicht leer sein",
 course_form_13 : "Die Route muss mindestens aus #1 Buchstaben bestehen",

 course_form_14 : "Das Treffen darf nicht leer sein",
 course_form_15 : "Das Treffen muss mindestens aus #1 Buchstaben bestehen",

 course_form_16 : "Die Abfahrt darf nicht leer sein",
 course_form_17 : "Die Abfahrt muss mindestens aus #1 Buchstaben bestehen",

 course_form_18 : "Die Rückfahrt darf nicht leer sein",
 course_form_19 : "Die Rückfahrt muss mindestens aus #1 Buchstaben bestehen",

 course_form_20 : "Das Kostenfeld darf nicht leer sein",
 course_form_21 : "Das Kostenfeld muss mindestens aus #1 Buchstaben bestehen",

 course_form_22 : "Das Infofeld muss mindestens aus #1 Buchstaben bestehen",

 course_form_23 : "Der Hinweis darf nicht leer sein",
 course_form_24 : "Der Hinweis muss mindestens aus #1 Buchstaben bestehen",
 course_form_25 : "Bitte noch das PDF-Formular hochladen",
 course_form_26 : "Bitte die Löschung noch bestätigen",
 course_form_27 : "Das Angebot wurde gelöscht",
 course_form_28 : "Es sind nur Dokumente im Format #1 erlaubt",
 course_form_29 : "Das Angebot wurde gespeichert",
 course_form_30 : "Das PDF-Dokument wurden hochgeladen",
 course_form_31 : "Error: Bitte informieren Sie uns per E-Mail",
 course_form_32 : "Der Upload darf #1 MB nicht übersteigen",
 course_form_33 : "Error: Upload Timeout ...",

 course_form_34 : "Die Anzahl der Plätze darf nicht leer sein",
 course_form_35 : "Die Anzahl der Plätze muss mindestens aus #1 Buchstaben bestehen",
 course_form_36 : "Die Leitung darf keine Sonderzeichen enthalten",

 login_form_user_01 : "Bitte den Benutzernamen eingeben",
 login_form_user_02 : "Der Benutzernamen muss mindestens aus #1 Buchstaben bestehen",
 login_form_user_03 : "Der Benutzernamen darf nur Buchstaben enthalten",

 login_form_passwd_01 : "Bitte das Passwort eingeben",
 login_form_passwd_02 : "Das Passwort muss mindestens aus #1 Zeichen bestehen",
 login_form_passwd_03 : "Das Passwort darf nur aus alphanumerische Zeichen bestehen",

 contact_form_char_01 : "Bitte nur die deutsche Sprache verwenden",

 contact_form_anrede_01 : "Bitte wählen Sie die Anredeform",

 contact_form_vorname_01 : "Der Vorname muss mindestens aus #1 Buchstaben bestehen",
 contact_form_vorname_02 : "Der Vorname darf nur Buchstaben enthalten!",

 contact_form_nachname_01 : "Bitte geben Sie Ihren Nachnamen ein",
 contact_form_nachname_02 : "Der Nachnamen muss mindestens aus #1 Buchstaben bestehen",
 contact_form_nachname_03 : "Der Nachnamen darf nur Buchstaben enthalten",

 contact_form_email_01 : "Bitte geben Sie Ihren E-Mail ein",
 contact_form_email_02 : "Die E-Mail muss mindestens aus #1 Zeichen bestehen",
 contact_form_email_03 : "Die E-Mail entspricht nicht den formalen Vorgaben",

 contact_form_phone_01 : "Die Nummer muss mindestens aus #1 Zeichen bestehen",
 contact_form_phone_02 : "Die Nummer muss dem Format \"123/12345\" entsprechen",

 contact_form_betreff_01 : "Bitte wählen Sie den Betreff aus",

 contact_form_message_01 : "Bitte geben Sie Ihre Mitteilung ein",
 contact_form_message_02 : "Ihre Mitteilung muss mindestens aus #1 Zeichen bestehen",
 contact_form_message_03 : "Ihre Mitteilung darf keine Sonderzeichen enhalten",

 contact_form_accept_01 : "Bitte akzeptieren Sie noch unsere Datenschutzerklärung",

 contact_form_lesson_01 : "Bitte geben Sie das Ergebnis der Rechenaufgabe ein",
 contact_form_lesson_02 : "Das Ergebnis stimmt nicht mit der Lösung überein",

 contact_form_logout : "Timeout: Die Session wurde beendet",

 contact_form_error : "Error: Bitte informieren Sie uns per E-Mail",
 contact_form_submit : "Vielen Dank, Ihre Daten wurde gesendet"

}

//*****************************************************************************
