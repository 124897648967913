
//*****************************************************************************

import { ctrAccordion } from "../common/page.accordion.js";

//*****************************************************************************

let setItem = 1;

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const accordion = new ctrAccordion;

const paramDep = urlParams.get("dep");

if(paramDep !== null) {

 switch(paramDep) {

  case "taekwondo": setItem = 1; break;
  case "breitensport": setItem = 2; break;
  case "emoki": setItem = 3; break;
  default: setItem = 0;

 }

 document.getElementById("departments").scrollIntoView();

}

accordion.init(setItem);

//*****************************************************************************
